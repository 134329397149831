<template>
    <div class="introduce">
       <div style="position:relative">
          <img class="bannerImg" src="../assets/images/introduceBannar.png" alt="">
        <div class="banner">
            <p class="title">智算中心是新型生产力</p>
            <p>正在赋能千行百业</p>
        </div>
        </div>
        <div class="panorama">
            <div class="advantage page_wrap">
                <div class="page_flex">
                    <div class="left" :style="{ 'backgroundImage': `url(${require('../assets/images/introduce/p1_1.png')})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem','border-radius':'0.2rem'}">
                        <!-- <img style="height: 3.8rem;width:85%" src="../assets/images/introduce/p1_1.png" alt=""> -->
                       <!-- <p>公司地理位置示意图</p> -->
                    </div>
                      <!-- <div class="right">
                        <img style="height: 3.8rem;width:85%" src="../assets/images/nishang.png" alt="">
                        <p>南京智能计算中心地理位置示意图</p>
                    </div> -->
                    <div class="right" style="padding-left:0.4rem">
                       <p class="about-us">关于我们</p>
                       <p class="About-us">About us</p>
                       <p style="margin-top:0.1rem;text-indent:2em">
                        南京中科逆熵科技有限公司成立于2020年11月，是<span>南京麒麟科技创新园国资参股</span>，南京智能计算中心指定的唯一运营企业。南京智能计算中心是由<span>麒麟科创园国资</span>
                        全资建设，总投资13 亿元，分三期建设，一期建成算力0.4Eops（INT16）（约700台服务器），于2021年7月正式投入运营。二期各型设备上架日前已基本完成，累计算力将达到0.9Eops（INT16）。
                       </p>
                       <p style="margin-top:0.1rem;text-indent:2em">
                        南京智算中心是长三角地区当前投运算力优异的智能计算中心。中科逆熵科技有限公司是中科寒武纪官方授权生态合作伙伴，与<span>中国科学技术大学等知名大学和研究所</span>有密切的合作关系，公司在人工智能算力平台运营、研发及生态拓展有丰富的经验。
                        公司致力于引领推动南京智算中心的算力应用，倾力打造 “核心平台+ 数据运营+ 生态合作”的人工智能产业生态圈，促进人工智能技术与传统产业结合，为解决方案供应商提供人工智能算力服务，运营的南京智算中心客户数超过<span>500</span>家。
                        公司核心成员均已在人工智能行业深耕多年，具备丰富的行业经验，并在深度学习、工业视觉、智慧交通、智慧安防等领域已完成多项发明专利和软件著作权。已获得ISO9001质量管理体系认证和江苏省科技型中小企业认证，“信息系统安全等级保护第三级”等资质。
                        AI赋能，共创美好未来。中科逆熵科技有限公司力争经过2~3年的努力，成为国内智能计算平台领域的引领者与创新者，为建设国产智能计算生态作出独特贡献。
                       </p>
                    </div>
                </div>
            </div>
            <div class="background">
                <div class="top">
                    <p class="team">我们的团队</p>
                    <p >Our teams</p>
                </div>
                <div class="center">
                    <div class="member">
                         <img src="../assets/images/introduce/p2_1.png" alt="" style="height:0.8rem;margin-bottom:0.05rem">
                        <p>团队成员:<span>50+人</span></p>
                         <p>核心研发团队:<span>30+人</span></p>
                    </div>
                    <div class="market">
                         <img src="../assets/images/introduce/p2_3.png" alt="" style="height:0.6rem">
                         <p>三大业务中心:</p>
                         <span>南京</span>
                    </div>
                </div>
                <div class="bottom">
                    <div class="quality">
                         <img src="../assets/images/introduce/p2_2.png" alt="" style="height:0.6rem">
                         <p>团队背景:<span>国资参股企业</span></p>
                         <p>核心成员来自:<span>中国科学技术大学</span></p>
                    </div>
                    <div class="picture" :style="{ 'backgroundImage': `url(${require('../assets/images/introduce/p2_4.jpg')})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%','border-radius':'0.2rem'}">
                        <!-- <img src="" alt=""> -->
                    </div>
                </div>
            </div>
            <!-- <div class="introduce-logo">
                <img src="../assets/images/introduce_extrotec.png" alt="">
            </div> -->
            <div class="services">
                <div class="left" style="z-Index:20;width: 80%;">
                    <div class="paragraph">
                        <p>01 <span>强大的AI算力服务</span></p>
                        <p>当前南京智算中心已运营的AI计算性能,达每秒80亿亿次(800P OpS),相当于50万台个人电脑的计算能力,即1小时可处理100亿张图像、300万小时语音翻译,或1万公里的自动驾驶AI数据处理任务。</p>
                    </div>
                      <div class="paragraph">
                        <p>02 <span>丰富的人工智能算法</span></p>
                        <p>我们建设国内首屈一指的视觉算法生态平台,致力于开拓人工智能在不同领域的开发与应用,为更多社会基础产业提供智慧升级解决方案。</p>
                    </div>
                      <div class="paragraph">
                        <p>03 <span>专业的产品研发思路</span></p>
                        <p>我们基于系统化思维,提供专业化服务,建设和谐发展的智能计算生态。实现建设、运营、应用三阶段统筹协同。与专业团队共建智能处理中心,以智算生态聚合带动形成多层级产业生态体系,赋能多个产业应用场景。</p>
                    </div>
                      <div class="paragraph" style="border-bottom:none">
                        <p>04 <span>完善的产品服务流程</span></p>
                        <p>在长期的实践探索中,我们逐渐建立起成熟完善的服务流程,能够保证服务的效率和质量。能够保证服务的效率和质量。通过诚信务实,积极沟通,持续创新,互惠互赢的服务理念,为实现客户价值而努力。</p>
                    </div>
                </div>
                <div class="right" style="z-Index:20;marign-left:-2rem;width:50%">
                    <p class="title">我们的服务</p>
                    <p>Our services</p>
                    <div :style="{ 'backgroundImage': `url(${require('@/assets/images/introduce/p3_1.jpg')})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'77%','margin-top':'0.5rem'}"></div>
                </div>
            </div>
            <div class="performance">
                <p>企业风采</p>
                <p>Corporate style</p>
                <div class="images">
                    <div v-for="(item,i) of imgList" :key="i">
                        <img :src="item.url" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="advantageCard">
                <div class="left">
                    <img class="imgHover animationLeft" src="../assets/images/yuanjing.png" alt="">
                </div>
                <div class="right animationRight">
                    <p class="hopeTitle">关于我们</p>
                    <p style="font-size: 0.2rem;font-family: Microsoft YaHei;color: #0061e3;line-height: 0.3rem;marginBottom:0.32rem">About us</p>
                    <p style="line-height:0.26rem">南京中科逆熵科技有限公司负责南京智能计算中心的运营、推广和维护服务，开展AI中台系统研发。
                    依托平台提供行业应用、创新创业、教育科研、论坛大赛等服务，构建智能芯片的生态合作圈，
                    倾力打造“核心平台+数据运营+生态合作“的智能产业生态圈，
                    促进人工智能技术与传统产业结合，面向全球提供人工智能算力服务</p>
                </div>
            </div>
            <p class="panoramaTitle">运营成果</p>
            <div class="result">
                <div class="case">
                    <div class="left">
                        <p class="registerTitle">人脸表情识别</p>
                        <div class="text">
                            主要功能：<br>
                            实时识别人脸表情<br>
                            快速获取人脸表情信息<br>
                            处理海量人脸表情数据<br>
                            技术特点:<br>
                            可移植: 支持多种边缘端摄像头设备。<br>
                            可扩展: 模块化, 可嵌入到各种应用系统中。<br>
                            自动化: 人工智能技术自动识别人脸表情，快速且高效<br>
                        </div>
                        <span class="tips">软著</span>
                    </div>
                    <div class="right">
                        <img src="../assets/images/face1.jpg" alt="">
                    </div>
                </div>
                <div class="case">
                    <div class="left">
                        <p class="registerTitle">人脸识别</p>
                        <div class="text">
                            主要功能:<br>
                            实时识别人脸身份<br>
                            快速获取人脸身份信息<br>
                            处理海量人脸身份数据<br>
                            技术特点:<br>
                            可移植: 支持多种边缘端摄像头设备。<br>
                            可扩展: 模块化, 可嵌入到各种应用系统中。<br>
                            自动化: 人工智能技术自动识别人脸身份，快速且高效<br>
                        </div>
                        <span class="tips">软著</span>
                    </div>
                    <div class="right">
                        <img src="../assets/images/face2.jpg" alt="">
                    </div>
                </div>
                <div class="case">
                    <div class="left">
                        <p class="registerTitle">人像分割</p>
                        <div class="text">
                            主要功能:<br>
                            快速实现人像自动抠图<br>
                            人像背景变换<br>
                            技术特点:<br>
                            易用：代码模块化，功能清晰化，非常方便使用。<br>
                            可扩展：代码可重用性高，适用性广，可根据使用场景进行二次开发。<br>
                        </div>
                        <span class="tips">软著</span>
                    </div>
                    <div class="right">
                        <img src="../assets/images/face3.jpg" alt="">
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Home',
    data () {
        return {
          imgList:[
              {url:require('../assets/images/introduce/p4_1.jpg')},
              {url:require('../assets/images/introduce/p4_2.png')},
              {url:require('../assets/images/introduce/p4_3.png')},
              {url:require('../assets/images/introduce/p4_4.png')}
          ]
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        toRoute (val) {
            this.$router.push(val)
        }
    }

}
</script>
<style lang="scss" >
.introduce {
    width: 100%;
    height: 100%;
    .banner{
       left:10%;
        transform: translateY(-60%);
        p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         font-size: 0.4rem;
        }
       p:nth-child(2){
        font-size: 0.4rem;
        margin-top: 0.2rem;
        display: block;
        border-bottom: none;
       }
    }
    .background{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    
    .panorama {
        // text-align: center;
        width: 100%;
        margin: 0 auto;
        .page_flex{
            align-items: center;
        }
        .panoramaTitle {
            text-align: center;
            font-size: 0.28rem;
            margin-top: 0.6rem;
            // background-color: #012961;
            color: #333;
            border-radius: 0.2rem;
            display: inline-block;
            padding: 0.1rem 0.2rem;
        }
        .advantage {
            width: 80%;
            // height: 4.5rem;
            margin: 0.5rem auto 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left,.right {
                width: 50%;
                height: 100%;
                p {
                    // line-height: 0.5rem;
                    // color: #a39797;
                    // font-size: 0.14rem;
                    font-size: 0.18rem;
                    font-weight: 400;
                    color: #232324;
                    line-height: 0.3rem;
                   span{
                    font-size: 0.18rem;
                    font-weight: 400;
                    color: #0061E3;
                   }
                }
            }
            .left {
                border-right: 1px solid #f5f1f1;
            }
        }
        .background{
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            padding: 60px 0;
            background: #F8F8F8;
            width: 100%;
            font-size: 0.2rem;
            line-height: 40px;
           .top{
            text-align: center;
               .team{
                  font-size: 0.3rem;
                  display: inline-block;
                  border-bottom: 1px solid #0061E3;
                  line-height: 0.4rem;
               }
                p:nth-child(2){
                  margin: auto;
                  color:#0061E3;
               }
           }
           .center{
               margin-top: 0.3rem;
               margin-right: 20%;
               width: 80%;
               display: flex;
               justify-content: space-around;
                height: 3rem;
                text-align: center;
               >div{
                   width: 30%;
                   height: 100%;
               }
           }
           .bottom{
               margin-left: 15%;
               width: 85%;
               margin-top: -3rem;
               display: flex;
               justify-content: space-around;
               align-items: flex-end;
                 height: 3rem;
                 text-align: center;
               >div{
                   width: 23%;
               }
           }
            span{
                   color:#0061E3
               }
               .picture{
                   height: 100%;
               }
               img{
                   display: block;
                   margin: auto;
                   margin-bottom: 0.2rem;
               }
        }
        .introduce-logo{
            margin-top: 0.2rem;
            width: 100%;
            img{
                width: 60%;
            }
        }
        .services{
            display: flex;
            // width: 70%;
            margin-top: 0.5rem;
            .left{
                // margin-left: 20%;
                padding:0 2.5rem 0 2rem;
                box-sizing: border-box;
                box-shadow: 0px 5px 20px 0px rgba(201, 201, 201, 0.35);
                width: 70%;
                text-align: left;
                background-color: #fff;
                .paragraph{
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #C1D1E6;
                    p:nth-child(1){
                      font-size: 0.2rem;
                      color: #C1D1E6;
                      line-height: 0.4rem;
                    span{
                        color: #0061E3;
                    }
                   }
                    p:nth-child(2){
                        font-size: 0.18rem;
                        text-indent: 2em;
                        line-height:0.4rem;
                    }
                }
               
            }
            .right{
                // padding-top: 0.5rem;
                width: 50%;
                text-align: center;
                margin-left:-2rem ;
                margin-top: 0.5rem;
                // position: relative;
                p:nth-child(1){
                    display: inline-block;
                    font-size: 0.3rem;
                    line-height: 0.4rem;
                    border-bottom: 1px solid #0061E3;
                }
                p:nth-child(2){
                    font-size: 0.25rem;
                    color:#0061E3;
                    line-height: 0.35rem;
                }
            }
        }
        .performance{
            width: 80%;
            margin:1rem auto 0;
            text-align: center;
            p:nth-child(1){
                display: inline-block;
                font-size: 0.3rem;
                line-height: 0.4rem;
                border-bottom: 1px solid #0061E3;
            }
            p:nth-child(2){
                color:#0061E3;
                font-size: 0.25rem;
                line-height: 0.35rem;
            }
            .images{
                margin-top: 0.5rem;
                display: flex;
                justify-content: space-between;
                >div{
                    width: 22%;
                    img{
                        width: 80%;
                        margin: auto;
                        border-radius: 0.05rem;
                    }
                }
            }
        }
        // .advantageCard {
        //     width: 80%;
        //     height: 4rem;
        //     margin: 0.5rem auto 0 auto;
        //     .left {
        //         width: 60%;
        //         height: 100%;
        //         float: left;
        //         position: relative;
        //         img {
        //             //width: 100%;
        //             height: 100%;
        //         }
        //     }
        //     .right {
        //         width: 30%;
        //         text-align: left;
        //         float: left;
        //         padding-left: 0.4rem;
        //         color: #999999;
        //         font-size: 0.14rem;
        //         .hopeTitle {
        //             font-size: 0.36rem;
        //             font-weight: 400;
        //             color: #0061E3;
        //         }
        //     }
        // }
        .result {
            height: 8rem;
            overflow: auto;
            .case {
                width: 100%;
                height: 3rem;
                background-color: #c7d2f3;
                margin: 0.5rem auto 0;
                padding-bottom: 0.2rem;
                border-bottom: 1px solid #ccc;
                .registerTitle {
                    text-align: center;
                    font-size: 0.24rem;
                    font-family: cursive;
                    font-weight: 700;
                }
                .left {
                    width: 59%;
                    height: 100%;
                    margin-top: 0.1rem;
                    text-align: left;
                    float: left;
                    position: relative;
                    padding: 0 0.1rem;
                    border-right: 1px solid #2e95d1;
                    .text {
                        margin-top: 0.2rem;
                        font-size: 0.14rem;
                        font-family: cursive;
                        line-height: 0.3rem;
                        padding-left: 2rem;
                    }
                    .tips {
                        color: #FFFFFF;
                        font-size: 0.24rem;
                        background: #0061E3;
                        display: inline-block;
                        width: 0.3rem;
                        border-radius: 3px;
                        position: absolute;
                        top: -0.4rem;
                        left: 0.4rem;
                        padding: 0.24rem 0.18rem;
                    }
                }
                .right {
                    width: 30%;
                    height: 100%;
                    float: left;
                    img {
                        height: 2.5rem;
                        margin: 0.25rem auto 0 auto;
                    }
                }
            }
        }
    }
}

</style>
<style scoped>
 .about-us{
    font-size : 0.3rem!important;;
    color:#010101;
    line-height:0.4rem !important;
    display: inline-block;
    border-bottom: 1px solid #0061E3;
   }
  .About-us{
    font-size:0.2rem !important;;
    /* font-family:Arial; */
    color:#0061E3 !important;
      }
</style>
